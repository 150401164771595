@import '../../../bulmaSettings.scss';

#virtual-rain-gauge-button {
  position: absolute;
  z-index: 1000;
  background-color: #F13077;
  color: white;
  font-size: 1.1rem;
  top: 20px;
  right: 20px;
  @include touch {
    width: 152px;
    height: 50px;
    left: calc(50vw - 20px);
    font-size: 1rem;
    bottom: 20px;
    top: unset;
    right: unset;
  }
}

#grab-button {
  position: absolute;
  z-index: 1000;
  background-color: #F13077;
  color: white;
  font-size: 1.1rem;
  top: 20px;
  right: 20px;
  @include touch {
    width: 152px;
    height: 50px;
    left: calc(10vw - 20px);
    font-size: 1rem;
    bottom: 20px;
    top: unset;
    right: unset;
  }
}
