.legendCell{
  padding: 0.25em 0.5em;
}

.legendRow {
  border: solid #dbdbdb;
  border-width: 0 0 1px;
  display: flex;
}

.legendRow:nth-last-of-type(1) {
  border-width: 0;
}

#legendBox {
  opacity: 0.9;
  padding: 5px;
}