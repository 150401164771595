@import '../../../bulmaSettings.scss';

#VirtualRainGaugePopupMenu {
  position: fixed;
  z-index: 1000;
  border-radius: 10px;
  bottom: 0px;
  left: calc(50vw - 210px);
  user-select: none;
  cursor: default;
  width: 320px;
  @include touch {
    width: 100vw;
    left: 0;
  }
}

.rainGaugePopupButton {
  width: 100% !important; 
  height: 100% !important;
  border-radius: 0 !important;
}

.rainGaugePopupItem {
  padding: 0 !important;
}

#cancelButton {
  background-color: #EEEFF5;
  color: #00598B;
}

#doneButton {
  background-color: #00598B;
  color: white;
}

#popupTitle {
  color: #00598B;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
}

#popupSubTitle {
  font-size: 1rem;
  text-align: center;
}