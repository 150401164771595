@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
// Import marker cluster CSS
@import '~react-leaflet-markercluster/dist/styles.min.css';
// Import leaflet styles
@import '../node_modules/leaflet/dist/leaflet.css';
// Import bulma config overrides
@import './bulmaSettings.scss';


.box {
    border-radius: 0 !important;
}

.modal {
    z-index: 9999 !important;
}

@media only screen and (max-width: 425px) {
    .title {
        font-size: 1.5rem !important;
    }
    .box {
        font-size: 0.6rem !important;
        padding: 10px !important;
      }
      nav * {
          font-size: 0.8rem !important;
      }
  }

@media only screen and (max-height: 450px) {
    .leaflet-bottom.leaflet-left {
        display: none;
    }
  }

.leaflet-container a {
    color: black !important
}

body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

html, body {
    overscroll-behavior-y: none;
    overflow: hidden;
    max-height: 100vh;
}

.data-pane {
    position: relative;
    @include touch {
        padding-top: 0 !important;
    }
    @include mobile {
        padding-bottom: 70px !important;
    }
  }

.cover {
    position: absolute;
    background: white;
    width: 100%;
    height: 3px;
}
  
.pane {
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: calc(100vh - 53px);

    @include touch {
        width: 100vw !important;
    }
    @include mobile {
        width: 100vw !important;
    }
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

#nprogress .bar {
    z-index:99999 !important;
}

.loading {    
    @include desktop {
        padding-top: 53px !important;
    }
}

.leaflet-popup-content a {
  color: black;
}

.leaflet-popup-content {
    margin-right: 10px;
    margin-left: 10px;
  }

.leaflet-popup-content-wrapper {
  padding: 1px 0px 1px 0px;
  width: 190px;
}

a.panel-block:hover {
    background-color: #f5f5f580;
}

.extraPad {
    padding-bottom: 15px;
}

.autoPointerEvents {
    pointer-events: auto !important;
}

.inlineFlex {
    display: inline-flex;
}
._badge-parent {position:relative;}
._badge-parent ._badge {
    position: absolute;
    width: 10px;
    height:10px;
    top: 5px;
    right: 7px;
    border-radius: 50%;
    background: red;
    color: white;
}

.is-scrollable {
    overflow: auto !important;
}