.csiroLogo {
  min-height: 45px;
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
}

.navbarHeight {
  min-height: 60px;
}

.marginLeft10 {
  margin-left: 10px;
}

.justifyLeft {
  justify-content: left;
}

.flexGrow1 {
  flex-grow: 1 !important;
}

.centerTitle {
  margin-top: 1.5vh !important;
}

.centerTitleMobile {
  margin-top: 2vh !important;
}

.is-size-1-3 {
  font-size: 1.3rem;
}