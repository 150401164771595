@import '../../bulmaSettings.scss';

.map {
  height:  calc(100vh - 60px);
  height: calc((var(--vh, 1vh) * 100) - 60px);
}

.dropMarker {
  cursor: pointer;
}

.map-box {
  border: 2px solid rgba(0,0,0,0.4) !important;
  height: 34px !important;
  border-radius: 3px !important;
}

//Map marker colours
.leaflet-marker-icon div span {
  color:rgb(255,255,255) !important;
  font-weight: 900 !important
}
.marker-cluster-small {background-color:rgba(221,157,0,.6) !important}
.marker-cluster-small div {background-color:rgb(221,157,0) !important}
.marker-cluster-medium {background-color:rgba(221,157,0,.6) !important}
.marker-cluster-medium div {background-color:rgb(221,157,0) !important}
.marker-cluster-large {background-color:rgba(221,157,0,.6) !important}
.marker-cluster-large div {background-color:rgb(221,157, 0) !important}

//Map marker icon
.leaflet-marker-icon {opacity: 1 !important}

.cluster-highlighted .marker-cluster-small {
  transition: box-shadow 2s;
  box-shadow: 0 0 20px 10px yellow;
  border-radius: 50%;
}
.cluster-highlighted .marker-cluster-medium {
  transition: box-shadow 2s;
  box-shadow: 0 0 20px 10px yellow;
  border-radius: 50%;
}
.cluster-highlighted .marker-cluster-large {
  transition: box-shadow 2s;
  box-shadow: 0 0 20px 10px yellow;
  border-radius: 50%;
}
.cluster-not-highlighted .marker-cluster-small {
  transition: box-shadow 2s;
  box-shadow: 0 0;
  border-radius: 50%;
}
.cluster-not-highlighted .marker-cluster-medium {
  transition: box-shadow 2s;
  box-shadow: 0 0;
  border-radius: 50%;
}
.cluster-not-highlighted .marker-cluster-large {
  transition: box-shadow 2s;
  box-shadow: 0 0;
  border-radius: 50%;
}

.not-highlighted {
  transition: box-shadow 2s;
  box-shadow: 0 0;
  border-radius: 50%;
}

.highlighted {
  transition: box-shadow 2s;
  box-shadow: 0 0 20px 10px yellow;
  border-radius: 50%;
}

.short-map {
  @include touch {
      height: 0 !important;
  }
}

.buttonSpacing {
  margin-top: 10px;
  margin-left: 10px;
}

.mapControlBox {
  display: inline-flex;
}