.locationPanelHeading {
  margin-top: 20px
}

.locationPanel {
  overflow-y: scroll;
  height: 100vh;
  padding-Bottom: 120px;
}

.locationBlock {
  display: block !important;
}

.sensor-indicator {
  margin-top: 10px;
}

.locationPaneSearch {
  border-radius: 0;
}

.panel-heading {
  padding: 0.8em 1em 0.25em !important;
  color: #00598B;
  background-color: rgba(255, 255, 255, 0) !important;
}

.locationListLocation {
  font-size: 0.8em;
  padding-top: 10px;
}

.is-medium.input {
  font-size: 1.25rem !important;
}