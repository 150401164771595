#alert {
  min-width: 250px;
  position: fixed;
  right: 10px;
  bottom: 30px;
  z-index: 999999;
}

.isFadingIn {
  animation: fadein 0.5s forwards;
}

.isFadingOut {
  animation: fadeout 0.5s forwards;
} 