@import '../../bulmaSettings.scss';
@import '~bulma-switch';

.app {
  overflow: hidden;
  scroll-behavior: smooth;
}

.fullHeight {
  min-height: 100vh;
}

.panes {
  @include touch {
      display:flex;
      flex-direction:column;
  }
}

.appLoadingOverlay {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.15);
  position: fixed;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-direction: column;

}

.margin-right-35vw {
  margin-right: 35vw;
}