.data-card {
  padding-right: 10px;
}

.dataPanel {
  overflow: hidden;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.noDataTitle {
  text-align: center;
   padding-top: 50%;
}

#stickyDiv {
  background-color: #ffffff;
  position: sticky;
  position: -webkit-sticky;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
  top: 0px;
  margin-bottom: 0;
}

.echarts-for-react {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}