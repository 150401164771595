.notificationBox {
    position: absolute;
    color: #3F4A72;
    right: 30%;
    left: 35%;
    min-width: 200px;
}

.notification1 {
    top: 360px
}

.loadNotification1 {
    top: 400px
}

.notification2 {
    top: 680px;
}

.loadNotification2 {
    top: 720px
}

@media only screen and (max-height: 770px) {
    .notification1 {
        top: 360px
    }
    
    .loadNotification1 {
        top: 400px
    }
    
    .notification2 {
        top: 680px;
    }
    
    .loadNotification2 {
        top: 720px
    }
}

@media only screen and (max-width: 1023px) {
    .notification1 {
        top: 310px
    }
    
    .loadNotification1 {
        top: 350px
    }
    
    .notification2 {
        top: 530px;
    }
    
    .loadNotification2 {
        top: 570px
    }
}

@media only screen and (max-width: 825px) {
    .notification1 {
        top: 360px
    }
    
    .loadNotification1 {
        top: 400px
    }
    
    .notification2 {
        top: 680px;
    }
    
    .loadNotification2 {
        top: 720px
    }
}