.graphValueBox1 {
    top: 90px;
    left: 80%;
}

.loadGraphValueBox1 {
    top: 130px;
    left: 80%;
}

.loadGraphValueBox2 {
    top: 450px;
    left: 80%;
}

.graphValueBox2 {
    top: 410px;
    left: 80%;
}

@media only screen and (max-height: 770px) {
    .graphValueBox1 {
        top: 90px;
        left: 70%;
    }

    .loadGraphValueBox1 {
        top: 140px;
        left: 70%;
    }

    .graphValueBox2 {
        top: 400px;
        left: 70%;
    }

    .loadGraphValueBox2 {
        top: 440px;
        left: 70%;
    }
}

@media only screen and (max-width: 1023px) {
    .graphValueBox1 {
        top: 90px;
        left: 70%;
    }

    .loadGraphValueBox1 {
        top: 130px;
        left: 70%;
    }

    .graphValueBox2 {
        top: 310px;
        left: 70%;
    }

    .loadGraphValueBox2 {
        top: 350px;
        left: 70%;
    }
}

@media only screen and (max-width: 825px) {
    .graphValueBox1 {
        top: 90px;
        left: 70%;
    }

    .loadGraphValueBox1 {
        top: 130px;
        left: 70%;
    }

    .graphValueBox2 {
        top: 410px;
        left: 70%;
    }

    .loadGraphValueBox2 {
        top: 450px;
        left: 70%;
    }
}

.tooltipBox {
    position: absolute;
    border: 2px solid #D1D3DD;
    border-radius: 5px;
    padding: 3px 10px 3px 9px;
    color: #3F4A72;
}