.dateZoomButton {
  margin-top: 0 imp !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 350px) {
  .dateZoomButton > li > button {
    padding-left: 5 !important;
    padding-right: 5 !important;
  }
}