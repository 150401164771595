@import '../../bulmaSettings.scss';

#dataHeader {
  min-height: 61px;
  width: 100%;
  margin: 0;
}

.is-fixed-top {
  @include desktop {
      width: inherit;
  }
  left: auto !important;
  right: auto !important;
  width: 100%;
  position: sticky !important;
}

.buttonSize {
  height: 61px;
  margin-top: 10px;
  margin-left: 10px;
}