.borderRadius {
  border-radius: 6px;
}

.colourVRG {
  color: #F13077;
}

.imagePosVRG {
  margin-left: calc(50% - 48px);
}